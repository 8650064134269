.RoundedButton--MainContainer{
    display: flex;
    border-radius: 10px;
    background-color: #FFB800;
    cursor: pointer;
    padding: 10px;
    height: fit-content;
    align-items: center;
    justify-content: center;
    width: fit-content;
}  
  .RoundedButton--MainContainer:hover {
    background-color: #cb9304;
  }
