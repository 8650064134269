.TestimonialChip--MainContainer{
    display: flex;
    flex-direction: column;
    width: 80%;
    align-items: center;
}
.TestimonialChip--AvatarContainer{
    display: flex;
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 50%;
}