.ContactDetailsSection--MainContainer{
    display: flex;
    flex-direction: column;
    background-color: #242424;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 20PX;
}
.ContactUsPage--ContactMethodContainer{
    display: flex;
    margin-bottom: 10px;
}