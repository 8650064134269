.carousel {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 300px; /* Adjust height as needed */
    background-color: #242424;
    display: flex;
    justify-content: center;
  }

  .slides-container {
    display: flex;
    position: relative;
    width: 100%;
  }

  .slide {
  opacity: 0;
  display:flex;
  transition: opacity 1s ease; /* Transition for smooth opacity change */
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  }
  .slide--active {
    opacity: 1;
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    justify-content: center;
  }
  
  .prev,
  .next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    color: #FFB800;
    font-size: 20px;
    cursor: pointer;
    padding: 10px;
    background-color: #242424;
    z-index: 2;
  }
  
  .prev {
    left: 10px;
  }
  
  .next {
    right: 10px;
  }