.mobile-menu {
    position: relative;
    cursor: pointer;
  }
  
  .menu-icon {
    position: absolute;
    top: 20px; /* Adjust the top position as needed */
    right: 20px; /* Adjust the right position as needed */
    z-index: 999; /* Ensure the icon is above other content */
    color: #ffb800;
  }
  
  .menu-options {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 170px;
    list-style-type: none;
    padding: 0;
    margin: 0; /* Add margin reset */
    margin-right: 20px;
    position: absolute;
    top: calc(70% + 5px); /* Adjust the top position to be closer to the icon */
    right: 0; /* Align the options with the right edge */
    background-color: #000000; /* Adjust background color as needed */
    border: 1px solid #ccc; /* Add border for better visibility */
    border-radius: 5px; /* Add border radius for rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add box shadow for depth */
    color: white;
    z-index: 10;
  }
  
  .menu-options li {
    padding: 10px 20px; /* Adjust padding as needed */
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
  }
  
  .menu-options li:hover {
    background-color: #787878; /* Add hover effect */
  }