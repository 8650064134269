.GalleryDisplaySection--MainContainer{
    display: flex;
    flex-direction: column;
    background-color: #242424;
    margin-top: 20px;
    align-items: center;
}
.GalleryDisplaySection--ACtionButtonContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}
.GalleryDisplay-CategoriesContainer{
    display: flex;
    overflow: scroll;
    border-bottom-color: aqua;
    border-bottom-width: 2px;
    border-bottom: solid #FFB800;
    width: 90%;
    margin-bottom: 10px;
    justify-content: center;
}
.category-icon{
    width: 40px;
    height: 40px;
    margin:10px;
    opacity: 1;
    transition: ease-in 0.3s;
}
.category-icon-inactive{
    width: 40px;
    height: 40px;
    margin:10px;
    opacity: 0.5;
    transition: ease-in 0.3s;
}
