
.InfoChip--MainContainer{
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 350px;
    min-height: 220px;
    border-radius: 20px;
    padding: 10px;
    margin: 10px;
    align-items: center;
    justify-content: space-between;
}
.InfoChip--BackgroundImage{
    position: absolute;
    display: flex;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    filter: opacity(1) brightness(50%);
}

.InfoChip--MainContainer:hover{
    transform: scale3d(1.02,1.02,1.02);
}


