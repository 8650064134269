.CTASection--MainContainer{
    display: flex;
    flex-direction: column;
    background-color: #242424;
    margin-top: 20px;
}
.CTASection--ACtionButtonContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
}