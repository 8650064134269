.Footer--MainContainer{
    display: flex;
    flex-direction: column;
}
.Footer--LogContainer{
    display: flex;
    height: 80px;
    overflow: hidden;
    background-color: black;
    justify-content: center;
}
.Footer--ContactAndDirectLinsContainer{
    display: flex;
    background-color: black;
    justify-content: space-around;
    height: 200px;
}
.Footer-LeftContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}
.Footer-RightContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    width: 200px;
}
.Footer-SocialMediaContainer{
    display: flex;
    align-items: center;
    width: 65%;
    justify-content: space-between;
}